<script setup>
import { onMounted, provide, watch, ref } from 'vue'
import TvbLayout from './layout/index.vue'
import { useWindowSize } from '@vueuse/core'
import { TvbConfig } from '@tvb-sz/tvb-headerfooter-frontend'
import '@tvb-sz/tvb-headerfooter-frontend/theme-default/index.css'
defineOptions({ name: 'TvbApp' })

const { width, height } = useWindowSize()

const size = ref(null)
const env = import.meta.env.VITE_ENV

const setWindowSize = () => {
  document.getElementById('tvb').style.setProperty('--window-width', `${width.value}px`)
  document.getElementById('tvb').style.setProperty('--window-height', `${height.value}px`)
  document
    .getElementById('tvb')
    .style.setProperty(
      '--header-height',
      `${document.querySelector('#tvb > .tvb-container .tvb-header')?.clientHeight || 0}px`
    )
  document
    .getElementById('tvb')
    .style.setProperty(
      '--footer-height',
      `${document.querySelector('#tvb > .tvb-container .tvb-footer')?.clientHeight || 0}px`
    )

  const sizeMap = {
    xl: [Infinity, 1201],
    l: [1200, 1025],
    m: [1024, 768],
    s: [767, 391],
    xs: [390, -Infinity]
  }
  for (const key in sizeMap) {
    const [max, min] = sizeMap[key]
    if (max >= width.value && width.value >= min) {
      size.value = key
      break
    }
  }
}

provide('width', width)
provide('height', height)
provide('setWindowSize', setWindowSize)
provide('size', size)

onMounted(() => {
  setWindowSize()
  setTimeout(setWindowSize, 300)
})

watch([width, height], () => setWindowSize())
</script>

<template>
  <tvb-config :env="env === 'prod' ? 'prod' : 'qa'">
    <tvb-layout>
      <router-view />
    </tvb-layout>
  </tvb-config>
</template>

<style lang="scss">
.tvb-main {
  min-height: calc(100vh - 215px - 64px);
}
</style>
