import dayjs from 'dayjs'

// 生成n位数字字母混合字符串
export function generateMixed(n) {
  const chars = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ]
  let res = ''
  for (let i = 0; i < n; i++) {
    const id = Math.floor(Math.random() * 36)
    res += chars[id]
  }
  return res
}

/**
 * 复制字符串到粘贴板
 * @param {string} str 将要复制的字符串
 */
export const copyString = (str) => {
  const copyDom = document.createElement('textarea')
  copyDom.value = str
  document.body.appendChild(copyDom)
  return new Promise((resolve) => {
    setTimeout(() => {
      try {
        copyDom.select()
        document.execCommand('Copy')
        document.body.removeChild(copyDom)
        resolve(true)
      } catch (err) {
        resolve(false)
      }
    }, 100)
  })
}

// 传入一个整数参数，函数处理成多少k的形式，且保留一位小数点
export const num2k = (num) => {
  if (num < 0) return 0
  let _num = num + ''
  // 若数字是四位数以下没到千位的，就直接返回
  if (_num.length < 4) return num
  else {
    // 获取倒数第四个数及之前/去掉后三个数/千位数之前
    let num1 = _num.substring(0, _num.length - 3)
    // 获取百位数
    let num2 = _num.substring(_num.length - 3, _num.length - 2)
    // 设置一个小数点
    let point = '.'
    // 完事后若百位数是0的话需要省略掉百位，及小数点
    if (num2 == 0) {
      num2 = ''
      point = ''
    }
    // 最后拼接千位数之前、小数点、百位数、和一个k
    if (num > 999000) return '999k+'
    return num1.concat(point, num2, 'k')
  }
}

/**
 * 过滤特殊字符
 * @param {*} stg
 * @returns
 */
export function filterSpecial(stg = '') {
  try {
    return stg.replaceAll(
      /[`~!@#$^&*()=|{}':;',“”"\\\[\]\.<>\/?~_·！@#￥……&*（）——|{}【】'；：""'。，、？《》+「」%\s]/g, // eslint-disable-line no-useless-escape
      '-'
    )
  } catch (error) {
    console.error(error)
    return stg
  }
}

/**
 * 返回日期相对时间
 * @param {number} time
 * @returns string
 */
export function toReativeTime(time) {
  if (time && dayjs(time).isValid()) {
    const unix = dayjs.unix(time)
    const now = dayjs()
    if (now.diff(unix, 's') < 60) {
      return `${now.diff(unix, 's')}秒前`
    } else if (now.diff(unix, 'm') < 60) {
      return `${now.diff(unix, 'm')}分鐘前`
    } else if (now.diff(unix, 'h') < 24) {
      return `${now.diff(unix, 'h')}小時前`
    } else {
      return unix.format('YYYY-MM-DD HH:mm')
    }
  } else {
    return ''
  }
}

export function toISOString(time) {
  if (time && dayjs(time).isValid()) return dayjs(time * 1000)?.toISOString()
  return ''
}

export function isMobileDevice() {
  return navigator?.userAgentData?.mobile || /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

